(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-spelpaus/assets/spelpaus.js') >= 0) return;  svs.modules.push('/components/payment/middleware-spelpaus/assets/spelpaus.js');
'use strict';

const {
  InteractionContext
} = svs.components.payment.common.context;
const {
  MiddlewarePaymentError
} = svs.components.payment.common.error;
const {
  logger
} = svs.components.payment.common;

const spelpaus = onInteraction => () => async next => {
  const exclusion = await svs.accountservices.player_exclusions.ExclusionsData.getExclusionData('tipsspel');
  logger.info("Checking exclusionInfo: ".concat(JSON.stringify(exclusion)));
  if (!exclusion || exclusion.canPlay) {
    return next();
  }
  const data = {
    exclusion
  };
  const title = exclusion.title || 'Du har en aktiv spelpaus';
  try {
    await onInteraction(new InteractionContext(InteractionContext.Severity.ERROR, InteractionContext.Code.SPELPAUS_ACTIVE, data, title));
  } catch (err) {
    logger.debug(err === null || err === void 0 ? void 0 : err.message, err);
  }
  return next(new MiddlewarePaymentError({
    clientCode: MiddlewarePaymentError.ErrorCodes.USER_HAS_EXCLUSION
  }));
};
setGlobal('svs.components.payment.middleware.spelpaus', spelpaus);

 })(window);